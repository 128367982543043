<template>
  <div>
      <router-view/>
  </div>
</template>

<script>
export default {
  name: 'overview',
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>

</style>
